import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux'
import store from './store'

import 'rc-checkbox/assets/index.css';
import 'react-toastify/dist/ReactToastify.css';
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import "rc-switch/assets/index.css";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
