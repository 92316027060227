const setBaseToken = (token) => {
    localStorage.setItem('baseToken', token);
}

const getBaseToken = () => {
    return (localStorage.getItem('baseToken') === "true");
}

const cleanAuth = () => {
    localStorage.clear();
}

const setLastview = (_id) => {
    let ar = JSON.parse(localStorage.getItem('lastview')) || [];
    ar = ar.filter((f) => f !== _id);
    ar.unshift(_id);
    localStorage.setItem('lastview', JSON.stringify(ar));
}

const getLastview = () => {
    let ar = JSON.parse(localStorage.getItem('lastview')) || [];
    return ar;
}

export {
    setBaseToken,
    getBaseToken,
    cleanAuth,
    setLastview,
    getLastview
}