const MENUDATA = [
    {
        "label": "CAMPAÑAS",
        "key"  : "menu1",
        "items": [
            {
                "label": "Dashboards",
                "icon" : "folder.png",
                "route": "/admin/dashboard"
            }
        ]
    },
    {
        "label": "AJUSTES",
        "key"  : "menu2",
        "items": [
            {
                "label": "Usuarios",
                "icon" : "userl.png",
                "route": "/admin/user"
            }
        ]
    }
];

const TIMEEXPIRED = [
    {
        "value" : "24 hours",
        "label" : "24 horas"
    },
    {
        "value" : "2 days",
        "label" : "2 dias"
    },
    {
        "value" : "7 days",
        "label" : "7 dias"
    },
    {
        "value" : "30 days",
        "label" : "1 mes"
    },
    {
        "value" : "1 years",
        "label" : "1 año"
    }
]

const ROLES = [
    {
        label: "SUPERUSER_ROLE",
        value: "SUPERUSER_ROLE"
    },
    {
        label: "USER_ROLE",
        value: "USER_ROLE"
    }
];

const COLORSDEFAULT = [
    "#F9F9F9",
    "#000000",
    "#353535",
    "#F47373",
    "#37D67A",
    "#2CCCE4",
    "#DCE775",
    "#FF8A65",
    "#BA68C8",
    "#FFFFFF"
];

export {
    MENUDATA,
    ROLES,
    TIMEEXPIRED,
    COLORSDEFAULT
}