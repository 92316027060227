import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getBaseToken } from "../common/localstorage";

const PrivateRoute = ({component: Component, ...rest}) => {
    return (

        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page
        <Route {...rest} render={props => (
            getBaseToken() ?
                <Component {...props} />
            : <Redirect to={`/login?redirect=${props.history.location.pathname}`} />
        )} />
    );
};

export default PrivateRoute;