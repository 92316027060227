import React, {useEffect, useState, useCallback} from 'react';
import { Route, Redirect } from 'react-router-dom';

import { getBaseToken } from "../common/localstorage";
import axiosPrivate from '../common/api_private';
import MyLoader from '../components/myloader';

const SuperUserRouteRoute = ({component: Component, ...rest}) => {

    const [validation, setValidation] = useState(0);

    const fetchData = useCallback(async () => {
        try {
            await axiosPrivate(`/api/auth/is/superuser`);
            setValidation(1);
        } catch (error) {
            setValidation(2);
        }
    },[]);

    useEffect(() => {
        if(getBaseToken()){
            fetchData();
        }
        else
        {
            setValidation(2);
        }
    },[]);

    switch (validation) {
        case 0:
            return (
                <div className="full-loader">
                
                    <MyLoader />

                </div>
            );
        case 1:
            return (
                <Route {...rest} render={props => (
                    <Component {...props} />
                )} />
            );
        case 2:
            return (
                <Redirect to={`/`} />
            );
        case 3:
            return (
                <Redirect to={`/login`} />
            )
        default:
            return (
                <Redirect to={`/login`} />
            );
    }
};

export default SuperUserRouteRoute;