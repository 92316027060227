import React, { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { withRouter, useHistory } from "react-router-dom";
import { withNamespaces } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import axiosPrivate from "../common/api_private";
import {updateData} from  "../redux/actions/user";

const ClientLayout = (props) => {

    const history = useHistory();
    const [dashboard, setDashboards] = useState([]);

    useEffect(() => {
        getData();
        fetchData();
    },[])

    const getData = async () => {
        try {
            let user = await axiosPrivate.get(`/api/user`);

            props.updateData(user.data.data);
            
        } catch (error) {
            console.log(error);
        }
    }

    const fetchData = async () => {
        
        let newData = [];
        let dashboard = await axiosPrivate.get(`api/dashboard`);
        newData = dashboard.data.data.data;
        setDashboards(newData);

        if(newData.length > 0 && !props.match.params.path){
            history.push(`/client/dashboard/${newData[0]._id}`);
        }

    }

    const logout = () => {
        confirmAlert({
            title: props.t('¿Esta seguro de cerrar su sesión?'),
            message: props.t(''),
            buttons: [
              {
                label: props.t('Sí'),
                onClick: async () => {

                    history.push('/logout');
                    
                }
              },
              {
                label: props.t('No'),
                onClick: () => {}
              }
            ]
        });
    }

    return (
        <>
        <div className="page-wrapper adminlayout">
            <div className="page-inner">
                <aside className="page-sidebar">
                    <div className="page-logo">
                        <a href="/" className="page-logo-link press-scale-down d-flex align-items-center position-relative justify-content-center" data-toggle="modal" data-target="#modal-shortcut">
                            <img src="/assets/images/logo2.png" alt="" className="logo-admin" />
                        </a>
                    </div>
                    <nav id="js-primary-nav" className="primary-nav" role="navigation">
                        <div className="nav-filter">
                            <div className="position-relative">
                                <input type="text" id="nav_filter_input" placeholder="Filter menu" className="form-control" tabIndex="0"/>
                                <a href="/" className="btn-primary btn-search-close js-waves-off" data-action="toggle" data-class="list-filter-active" data-target=".page-sidebar">
                                    <i className="fal fa-chevron-up"></i>
                                </a>
                            </div>
                        </div>
                        <div className="info-card">
                            <img src="/assets/images/usuario.png" className="profile-image rounded-circle" alt=""/>
                            <div className="info-card-text">
                                <a href="/" className="d-flex align-items-center text-white">
                                    <span className="text-truncate text-truncate-sm d-inline-block">
                                        {props.user.data.displayName}
                                    </span>
                                </a>
                            </div>
                            <img src="/assets/smartadmin/img/card-backgrounds/cover-2-lg.png" className="cover" alt="cover"/>
                            <a href="/" className="pull-trigger-btn" data-action="toggle" data-class="list-filter-active" data-target=".page-sidebar" data-focus="nav_filter_input">
                                <i className="fal fa-angle-down"></i>
                            </a>
                        </div>
                        <ul id="js-nav-menu" className="nav-menu">
                            {dashboard.map((m, i) => {
                                let active = false;
                                if(props.match.params.path === m._id){
                                    active = true;
                                }
                                return (
                                    <li key={i} className={(active) ? `active` : ``} onClick={() => {history.push(`/client/dashboard/${m._id}`)}}>
                                        <a><span className="nav-text">{m.title}</span></a>
                                    </li>
                                )
                            })}
                        </ul>
                        <div className="filter-message js-filter-message bg-success-600"></div>
                    </nav>
                </aside>
                <div className="page-content-wrapper">
                    <header className="page-header" role="banner">
                        <div className="page-logo">
                            <a href="#a" className="page-logo-link press-scale-down d-flex align-items-center position-relative" data-toggle="modal" data-target="#modal-shortcut">
                                <img src="/assets/images/logo.png" alt="SmartAdmin WebApp" aria-roledescription="logo"/>
                                <span className="page-logo-text mr-1">SmartAdmin WebApp</span>
                                <span className="position-absolute text-white opacity-50 small pos-top pos-right mr-2 mt-n2"></span>
                                <i className="fal fa-angle-down d-inline-block ml-1 fs-lg color-primary-300"></i>
                            </a>
                        </div>
                        <div className="hidden-md-down dropdown-icon-menu position-relative">
                            <a href="#a" className="header-btn btn js-waves-off" data-action="toggle" data-class="nav-function-hidden" title="Hide Navigation">
                                <i className="ni ni-menu"></i>
                            </a>
                            <ul>
                                <li>
                                    <a href="#a" className="btn js-waves-off" data-action="toggle" data-class="nav-function-minify" title="Minify Navigation">
                                        <i className="ni ni-minify-nav"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#a" className="btn js-waves-off" data-action="toggle" data-class="nav-function-fixed" title="Lock Navigation">
                                        <i className="ni ni-lock-nav"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="hidden-lg-up">
                            <a href="#a" className="header-btn btn press-scale-down" data-action="toggle" data-class="mobile-nav-on">
                                <i className="ni ni-menu"></i>
                            </a>
                        </div>
                        <div className="search">
                            <form className="app-forms hidden-xs-down" role="search" action="page_search.html" autoComplete="off">
                                <input type="text" id="search-field" placeholder="Search for anything" className="form-control" tabIndex="1"/>
                                <a href="#a" className="btn-danger btn-search-close js-waves-off d-none" data-action="toggle" data-class="mobile-search-on">
                                    <i className="fal fa-times"></i>
                                </a>
                            </form>
                        </div>
                        <div className="d-flex my-auto box-dashboard-list">
                            {dashboard.map((d, i) => {
                                let active = ``;
                                if(props.match.params.path === d._id){
                                    active = `box-dashboard-active`;
                                }
                                return (
                                    <div className={`box-dashboard ${active}`} onClick={() => {history.push(`/client/dashboard/${d._id}`)}}>
                                        <span>{d.title}</span>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="ml-auto d-flex">
                            <div className="hidden-sm-up">
                                <a href="#a" className="header-icon" data-action="toggle" data-class="mobile-search-on" data-focus="search-field" title="Search">
                                    <i className="fal fa-search"></i>
                                </a>
                            </div>
                            <div>
                                <a href="#a" data-toggle="dropdown" title="" className="header-icon d-flex align-items-center justify-content-center ml-2">
                                    <img src="/assets/images/usuario.png" className="profile-image rounded-circle mr-2" alt=""/>
									<i className="ni ni-chevron-down hidden-xs-down"></i>
                                </a>
                                <div className="dropdown-menu dropdown-menu-animated dropdown-lg">
                                    <div className="dropdown-header bg-trans-gradient d-flex flex-row py-4 rounded-top">
                                        <div className="d-flex flex-row align-items-center mt-1 mb-1 color-white">
                                            <span className="mr-2">
                                                <img src="/assets/images/usuario.png" className="rounded-circle profile-image" alt=""/>
                                            </span>
                                            <div className="info-card-text">
                                                <div className="fs-lg text-truncate text-truncate-lg">{props.user.data.displayName}</div>
                                                <span className="text-truncate text-truncate-md opacity-80">{props.user.data.role}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dropdown-divider m-0"></div>
                                    <div className="dropdown-divider m-0"></div>
                                    <a href="/client/changepassword" className="dropdown-item">
                                        <span data-i18n="drpdwn.fullscreen">Cambiar contraseña</span>
                                        <i className="float-right text-muted fw-n"></i>
                                    </a>
                                    <a href="#a" className="dropdown-item" data-action="app-fullscreen">
                                        <span data-i18n="drpdwn.fullscreen">Fullscreen</span>
                                        <i className="float-right text-muted fw-n">F11</i>
                                    </a>
                                    <a href="#a" className="dropdown-item" data-action="app-print">
                                        <span data-i18n="drpdwn.print">Print</span>
                                        <i className="float-right text-muted fw-n">Ctrl + P</i>
                                    </a>
                                    <div className="dropdown-divider m-0"></div>
                                    <span className="dropdown-item fw-500 pt-3 pb-3" onClick={() => {logout()}}>
                                        <span data-i18n="drpdwn.page-logout" >Logout</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </header>
                    <main id="js-page-content" role="main" className="page-content">
                        {props.children}
                    </main>
                </div>
            </div>
        </div>
        
        </>
    );
}

const mapStateToProps = ({user}) => ({
    user,
})
  
const mapDispatchToProps = dispatch =>
    bindActionCreators(
    {
        updateData: data => updateData(data),
    },
    dispatch
);

export default withRouter(
	connect(
        mapStateToProps,
        mapDispatchToProps
    )(withNamespaces()(ClientLayout))
);