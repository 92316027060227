import axios from 'axios';
import i18n from '../i18n';

import { setBaseToken } from "./localstorage";

const myAxios = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    common: {  
      //"Authorization"  : `Bearer ${getBaseToken()}`,
      "Accept-Language": `${i18n.language}`
    }
  }
});
 
myAxios.interceptors.request.use(
  async config => {
    config.headers = { 
      //'Authorization': `Bearer ${getBaseToken()}`,
      "Accept-Language": `${i18n.language}`
    }
    config.withCredentials = true;
    return config;
  },
  error => {
    Promise.reject(error)
});

myAxios.interceptors.response.use((response) => {
  return response
}, async function (error) {
  const originalRequest = error.config;
  if(error.response){
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/auth/refresh`,{
          website: true
        },{
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_PUBLIC_KEY}` 
        }});     
        setBaseToken(true);
        //axios.defaults.headers.common['Authorization'] = 'Bearer ' + tokenRefreshResponse.data.data.token;
        return myAxios(originalRequest);
      } catch (error) {
        if(window.location.pathname !== "/login"){
          window.location.href = "/login";
        }
      }
    }
  }
  return Promise.reject(error);
});

export default myAxios;