import './App.css';
import './assets/css/style.scss';
import MyRouts from './routers/routs';
import i18n from './i18n';

function App() {

  console.log(i18n.language);
    
  return (
    <div className="App">
        <MyRouts />
    </div>
  );
}

export default App;
