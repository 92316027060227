import React, {lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ToastContainer } from 'react-toastify';

import MyLoader from "../components/myloader";
import PrivateRoute from "../utils/PrivateRoute";

import AdminLayout from '../components/adminlayout';
import ClientLayout from '../components/clientlayout';
import SuperUserRouteRoute from "../utils/SuperUserRoute";

const HomePage = lazy(() => import("../pages/home_page"));
const DashboardPage = lazy(() => import("../pages/dashboard_page"));
const DashboardListPage = lazy(() => import("../pages/dashboardlist_page"));
const DashboardPublicPage = lazy(() => import("../pages/dashboardpublic_page"));
const UserListPage = lazy(() => import("../pages/userlist_page"));
const ChangePasswordPage = lazy(() => import("../pages/changepassword_page"));
const LoginPage = lazy(() => import("../pages/loginhub_page"));
const RegisterPage = lazy(() => import("../pages/register_page"));
const LogoutPage = lazy(() => import("../pages/logout_page"));
const NotFoundPage = lazy(() => import("../pages/notfound_page"));

class MyRouts extends React.Component {
  render() {
    return (
      <div>
        <ToastContainer />
        <Router>
          <div>
            <Suspense fallback={
                <div className="full-loader">
                  <MyLoader/>
                </div>
            }>
              <Switch>
                <Route exact path="/" component={HomePage} />
                <Route exact path="/login" component={LoginPage} /> 
                <Route exact path="/register" component={RegisterPage} /> 
                <Route exact path="/logout" component={LogoutPage} /> 
                <PrivateRoute exact path="/dashboard/complete/:id" component={DashboardPage} />  
                <Route path={['/admin/:path?','/admin/:path/:path?']} exact>
                  <AdminLayout>
                    <Switch>
                      <SuperUserRouteRoute exact path="/admin" component={DashboardListPage} />   
                      <SuperUserRouteRoute exact path="/admin/dashboard" component={DashboardListPage} />   
                      <SuperUserRouteRoute exact path="/admin/dashboard/:id" component={DashboardPage} />
                      <PrivateRoute exact path="/admin/changepassword" component={ChangePasswordPage} /> 
                      <SuperUserRouteRoute exact path="/admin/user" component={UserListPage} />  
                    </Switch>
                  </AdminLayout>
                </Route>
                <Route path={['/client/:path?','/client/:path/:path?']} exact>
                  <ClientLayout>
                    <Switch>
                      <PrivateRoute exact path={['/client', '/client/dashboard','/client/dashboard/:id']} component={DashboardPage} />   
                      <PrivateRoute exact path="/client/changepassword" component={ChangePasswordPage} /> 
                    </Switch>
                  </ClientLayout>
                </Route>
                {/*<Route exact path="/forgotpassword" component={ForgotPasswordPage} />*/}
                <Route exact path="/dashboard/public" component={DashboardPublicPage} />
                <Route path="" component={NotFoundPage} />
              </Switch>
            </Suspense>
          </div>
        </Router>
      </div>
    );
  }
}
export default MyRouts;
