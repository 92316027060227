export const UPDATE_DATA = 'UPDATE_DATA';


const initialState = {
  data        : {},
}

const fn = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_DATA:
        return {
            ...state,
            data: action.data,
        }
    default:
        return state
  }
}

export default fn;

export function updateData(data) {
  return {
    type: UPDATE_DATA,
    data: {
      displayName: data.displayName,
      photoURL   : data.photoURL,
      role       : data.role
    }
  }
}
